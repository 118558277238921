//import { PageType, checkStock, checkStockSp, getPageType, } from "../Shared/SiteCommon";

import { toEstimate ,shippingDateSelectionReplace } from "../Shared/SiteCommon";

namespace PackjapanSiteCommon {
    var siteId = 6;
   /* jQuery(function () {
        switch (getPageType()) {
            case PageType.PcProduct:
                checkStock(siteId);
                break;
            case PageType.SpProduct:
                checkStockSp(siteId);
                break;
            default:
        }
    });*/
    jQuery(function ($) {
        removeCart1Text();
        setupEstimateButton();

        shippingDateSelectionReplace();

    });

    function removeCart1Text() {
        if (document.location.href.indexOf("/cart/1") == -1)
            return;
        var parentDiv = document.getElementById("shippingdatetime_box_1");
        if (parentDiv == null)
            return;

        if (parentDiv.childNodes.length < 1)
            return;

        if (parentDiv.childNodes[0].textContent.indexOf("※お届け日はご指定いただけません") == -1)
            return;
        parentDiv.childNodes[0].remove();
    }

    function setupEstimateButton() {
        //カートの最初のページのみ発動
        if (document.getElementById("shoppingcart_page_step0") != null) {
            const form = jQuery("#register");
            const formBtns = form.find(".form_btn");
            const lastElem = formBtns.last();
            lastElem.append(
                `<span class="global_btn registerinput_btn">
    <button type="button" id="estimate" class="registerinput btn_size_xxlarge btn_color_emphasis">
        <span class="button_head_space"></span>
        <span class="button_text">見積書発行</span>
        <span class="button_end_space"></span>
    </button>
</span>`);
            const estBtn = document.getElementById("estimate");
            estBtn.onclick = <any>((a, b) => {
                toEstimate(siteId);
            });
        }
    }
}